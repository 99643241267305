import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

// next & prev
// schema data
// breadcrumbs
// title
// images
// form

export default function LocationTemplate({ data }) {
	const { markdownRemark } = data;
	const { frontmatter, html } = markdownRemark;
	return (
		<Layout>
			<SEO title={frontmatter.title || 'Flowerwall location'} description={`Need to rent a flowerwall for an event in ${frontmatter.title}? We can help you with that.`} />
			<div>
				<h1>{frontmatter.title}</h1>
				<h2>{frontmatter.date}</h2>
				<div className="" dangerouslySetInnerHTML={{ __html: html }} />
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
	query($path: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				path
				title
			}
		}
	}
`;
